















import Vue from "vue";
import Component from "vue-class-component";
import { namespace } from "vuex-class";
import CodeEditor from "@/components/CodeEditor.vue";

const customCSSNamespace = namespace("CustomCSS");

@Component({ components: { CodeEditor } })
export default class StatisticsConfig extends Vue {
  isLoading = false;

  @customCSSNamespace.Getter("all")
  customCss!: any;

  @customCSSNamespace.Action("fetchAll")
  fetchCustomCSS!: () => Promise<void>;

  @customCSSNamespace.Action("update")
  update!: (statisticsConfig: any) => Promise<void>;

  async doSave() {
    this.isLoading = true;
    await this.update(this.customCss);
    await this.$store.dispatch("Styling/generate");
    this.isLoading = false;
  }

  async doFetchAll() {
    this.isLoading = true;
    await this.fetchCustomCSS();
    this.isLoading = false;
  }

  created() {
    this.doFetchAll();
  }
}
